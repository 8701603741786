require('es6-promise').polyfill();
import 'nodelist-foreach-polyfill';

window.addEventListener('DOMContentLoaded', () => {
  try {
    // range slider
    const money = document.querySelector('#money'),
      month = document.querySelector('#month'),
      sum = document.querySelector('#sum'),
      cost = document.querySelector('#cost'),
      term = document.querySelector('#term'),
      payment = document.querySelector('#payment');


    money.addEventListener('input', () => {
      sum.value = `${(money.value * 90 / 100).toString().replace(/(\d)(?=(\d{3})+([^\d]|$))/g, '$1 ')} руб.`;
      cost.value = `${money.value.toString().replace(/(\d)(?=(\d{3})+([^\d]|$))/g, '$1 ')} руб.`;
      payment.value = `${(((money.value * 90 / 100) * 4 / 100)).toString().replace(/(\d)(?=(\d{3})+([^\d]|$))/g, '$1 ')} руб.`;
    });
    month.addEventListener('input', () => {
      if (month.value == 1 || month.value == 21 || month.value == 31) {
        term.value = `${month.value} месяц`;
      } else if (month.value <= 4 || month.value == 22 || month.value == 23 || month.value == 24 || month.value == 32 || month.value == 33 || month.value == 34) {
        term.value = `${month.value} месяцa`;
      } else {
        term.value = `${month.value} месяцев`;
      }

    });
  } catch (error) {}


  // burger menu
  const menuBurger = document.querySelector('#burger'),
        menu = document.querySelector('.hero-header__menu'),
        menuItems = document.querySelectorAll('.hero-header__menu ul li a');
  menuBurger.addEventListener('click', () => {
    menuBurger.classList.toggle('active');
    menu.classList.toggle('active');
  });
  menuItems.forEach(item => {
    item.addEventListener('click', () => {
      menuBurger.classList.remove('active');
      menu.classList.remove('active');
    }); 
  });

  // modal
  const modal = document.querySelector('.modal'),
        modalOpen = document.querySelectorAll('[data-modal]');
  
  function openModal() {
    modal.classList.add('show', 'fade');
    modal.classList.remove('hide');
    document.body.style.overflow = 'hidden';
  }
  

  modalOpen.forEach(item => {
    item.addEventListener('click', () => {
      openModal();
    });
  });
  function closeModal() {
      modal.classList.remove('show', 'fade');
      modal.classList.add('hide');
      document.body.style.overflowY = '';
  }
    modal.addEventListener('click', (e) => {
      if (e.target === modal || e.target.getAttribute('data-close') == '') {
        closeModal();
      }
    });

    // Forms

    const forms = document.querySelectorAll('form');
    const message = {
        success: 'Спасибо! Скоро мы с вами свяжемся',
        failure: 'Что-то пошло не так...'
    };

    forms.forEach(item => {
        postData(item);
    });

    function postData(form) {
        form.addEventListener('submit', (e) => {
        
          e.preventDefault();

          let statusMessage = document.createElement('div');
          statusMessage.classList.add('status');
          form.appendChild(statusMessage);
      
          const request = new XMLHttpRequest();
          request.open('POST', 'server.php');
          request.setRequestHeader('Content-type', 'application/json; charset=utf-8');
          const formData = new FormData(form);

          const object = {};
          formData.forEach(function(value, key){
              object[key] = value;
          });
          const json = JSON.stringify(object);

          request.send(json);
          request.addEventListener('load', () => { 
              if (request.status === 200) {
                window.location.href = 'thanks.html';
              } else {
                  showThanksModal(message.failure);
                  form.reset();
                  setTimeout(() => {
                    statusMessage.remove();
                }, 2000);
              }
          });
      });
  }
      

    function showThanksModal(message) {
        const prevModalDialog = document.querySelector('.modal__dialog');

        prevModalDialog.classList.add('hide');
        openModal();

        const thanksModal = document.createElement('div');
        thanksModal.classList.add('modal__dialog');
        thanksModal.innerHTML = `
            <div class="modal__content">
                <div class="modal__close" data-close>×</div>
                <div class="modal__title">${message}</div>
            </div>
        `;
        document.querySelector('.modal').append(thanksModal);
        setTimeout(() => {
            thanksModal.remove();
            prevModalDialog.classList.add('show');
            prevModalDialog.classList.remove('hide');
            closeModal();
        }, 4000);
    }

    // policy

    const policyModal = document.querySelector('.policy'),
          policyOpen = document.querySelectorAll('[data-policy]');
          // policyClose = document.querySelector('[data-close-policy]');

    policyOpen.forEach(item => {
      item.addEventListener('click', ()=> {
        policyModal.classList.remove('hide');
        policyModal.classList.add('show', 'fade');
        document.body.style.overflow = 'hidden';
      });
    });    
    
    function closeModalPolicy() {
      policyModal.classList.remove('show', 'fade');
      policyModal.classList.add('hide');
      document.body.style.overflowY = '';
  }
    policyModal.addEventListener('click', (e) => {
      if (e.target === policyModal || e.target.getAttribute('data-close-policy') == '') {
        closeModalPolicy();
      }
    });



});

$(document).ready(function () {

  $("a[href^='#']").click(function (e) {
    e.preventDefault();

    var position = $($(this).attr("href")).offset().top;

    $("body, html").animate({
      scrollTop: position
    } /* speed */ );
  });

  // слайдер
  $('.hero__slider').slick({
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1
  });
  if(location.toString().indexOf('pts') !== -1) {
    setTimeout(() => {
      $('.hero__slider').slick('slickGoTo', 0);
    });
    } else if(location.toString().indexOf('avto') !== -1) {
      setTimeout(() => {
        $('.hero__slider').slick('slickGoTo', 1);
      });
    } else if(location.toString().indexOf('lombard') !== -1) {
      setTimeout(() => {
        $('.hero__slider').slick('slickGoTo', 2);
      });
      }
    
  $('.reviews__slider').slick({
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [{
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      }
    }]
  });
		let w = $(window).width();
    if (w <= 750) {
      $('.advantages__row').slick({
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true
      });
    }


  // -------------------------------------------- ymaps -->

  var myMap2;
  ymaps.ready(init2);

  function init2() {
    myMap2 = new ymaps.Map('map', {
        center: [55.714764, 37.761297],
        zoom: 16,
        controls: []
      }),
      myMap2.behaviors
      .disable(['rightMouseButtonMagnifier', 'scrollZoom']);
    myPlacemark = new ymaps.Placemark([55.717320, 37.763602], {
      hintContent: [
        ''
      ].join('')
    }, {
      iconLayout: 'default#image',
      iconImageHref: 'img/pick_map.png',
      iconImageSize: [50, 70],
      iconImageOffset: [-15, -42]
    });
    myMap2.geoObjects.add(myPlacemark);
  }
});